import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { val }  from '../components/cssvalue'
import {PanelWrap} from '../pages/index.js'
import {MainWrap} from '../pages/web.js'

// STYLE
const ListLaw = styled.dl`;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  > dt {
    font-weight: bold;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
  > dd {
    background: ${val.BG};
    padding: 5px 10px;
    margin: 5px 0 0 10px;
  }

`;
const Small = styled.p`
  max-width: 600px;
  margin: 40px auto 0;
  font-size: 12px;
`;
const Law = ({data, location}) => {
  return (
    <Layout location={location}>
      <Seo
        title="特定商取引法に基づく表記"
        description=""
        pagepath={location.pathname}
        robots="noindex"
      />
      <MainWrap>
        <div className="-jacket">
          <h1>特定商取引法に基づく表記</h1>
          <PanelWrap>
            <div className="-inner">
              <ListLaw>
                <dt>販売業者の名称</dt>
                <dd>鳥獣花木苑</dd>
                <dt>事務所所在地</dt>
                <dd>
                  東京都港区浜松町2丁目2番15号　浜松町ダイヤビル2Ｆ
                </dd>
                <dt>公開メールアドレス</dt>
                <dd>
                  h@choju-kaboku-en.com
                </dd>
                <dt>事業者のホームページURL</dt>
                <dd>
                  https://choju-kaboku-en.com
                </dd>
                <dt>申込みの有効期限</dt>
                <dd>
                  お見積をご提出させていただいた翌日から起算して30日以内にご注文をいただけない場合にはキャンセルとさせていただきます。
                </dd>
                <dt>サービスの料金</dt>
                <dd>
                  お見積書等により予めご提示します。
                </dd>
                <dt>料金の支払時期</dt>
                <dd>
                  お見積もり書ご提出後着手前に着手金をお支払いいただきます。残金は成果物の納品後30日以内にお支払いいただきます。
                </dd>
                <dt>サービス料金以外の必要料金</dt>
                <dd>
                1) 消費税<br />
                2）銀行振込によるお支払いの場合の振込手数料<br />
                3）宅配便等により納品する場合の送料
                </dd>
                <dt>サービス成果物の納品方法と引き渡し時期</dt>
                <dd>
                  デジタル製品の場合は所定の領域へのデータアップロードまたは製品データの電磁的送信若しくは郵送等により、納品日または別途定めた時期に引き渡します。<br />
                  有体製品の場合は宅配便等により納品日より7日以内に発送をおこないまたは別途定めた時期に引き渡します。<br />
                  口頭またはEメール等による情報提供により役務の提供となる場合はそのときに引き渡しがあったものとします。
                </dd>
                <dt>お支払方法</dt>
                <dd>
                  銀行振込<br />
                  クレジットカード決済
                </dd>
                <dt>返品・交換・キャンセル等</dt>
                <dd>
                本サービスの性質上、着手後のキャンセル・返品・返金はお受けしておりません。<br />
                やむを得ない事情により着手後のキャンセルがあった場合でも着手金の返金は行われず、進捗具合に応じたキャンセル料が着手金の額を超える場合にはキャンセル料をお支払いいただきます。その時点で納品できる成果物がある場合には納品させていただきます。<br />
                月額等継続契約のキャンセルについては、月の途中の解約となった場合でも1ヶ月分の料金が発生し、日割清算等による返金を含めた一切の返金は行われません。
                </dd>
              </ListLaw>
               <Small>※ここに記載されていない特定商取引法の表示事項につきましては、お問い合わせフォームよりご請求いただければ、遅滞なく開示させていただきます。
               </Small>
            </div>
          </PanelWrap>
        </div>
      </MainWrap>
    </Layout>
  )
}
export default Law
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`
